import * as React from "react"
import styled from "styled-components"

const ContentButton = styled.button`
  width: ${p =>
    p.type === "large" ? "auto" : p.type === "small" ? "112px" : "209px"};
  height: ${p =>
    p.type === "large" ? "auto" : p.type === "small" ? "36px" : "48px"};
  background-color: ${p =>
    p.background === "naranja" ? "#49B3CB" : "#F2AE2C"};
  /* background-color: #3374ba; */
  color: #292929;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  line-height: 20px;
  font-weight: bold;
  font-size: 20px;
  overflow: hidden;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  padding: 1em;
    display: flex;
    align-items: center;
  &::after {
    border-radius: 4px;
    background-color: ${p =>
      p.background === "naranja" ? "#2a6470" : "#F2AE2C"};
    /* background-color: #004182; */
    content: "";
    display: block;
    height: auto;
    width: auto;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  &:hover::after {
    transform: translate(0, 0);
  }
  &:hover {
    border: none;
    color: black;
    transform: scale(1.05);
    will-change: transform;
    cursor: pointer;
  }
  @media (max-width: 1400px) {
    /*max-width: 300px;
    max-height: 38px;*/
    font-size: 18px;
  }
  @media (max-width: 1200px) {
   /*max-width: 230px;
   max-height: 35px;*/
    padding: 0.7em;
    font-size: 16px;
  }
  @media (max-width: 900px) {
   /*max-width: 230px;
    padding: 0 5px;*/
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }
`
export const Button01 = ({ type, children, background }) => {
  return (
    <ContentButton background={background} type={type}>
      {children}
    </ContentButton>
  )
}
